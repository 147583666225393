/* istanbul ignore file */
export const UNAUTHENTICATED_ERROR = '/error/unauthenticated'
export const GENERIC_ERROR = '/error'
export const SHELL_SETTINGS_ROUTE = '/shell-settings'
export const CALENDAR_SETTINGS_ROUTE = `${SHELL_SETTINGS_ROUTE}/calendar`
export const APPEARANCE_SETTINGS_ROUTE = `${SHELL_SETTINGS_ROUTE}/appearance`
export const SETTINGS_ROUTE = '/settings'
export const SETTINGS_GENERAL_ROUTE = `${SETTINGS_ROUTE}/general`
export const GTC_GUIDE_ROUTE = '/guide'
export const KEYBOARD_SHORTCUT_SETTINGS_ROUTE = `${SHELL_SETTINGS_ROUTE}/keyboard-shortcut`
export const NOTIFICATIONS_SETTINGS_ROUTE = `${SHELL_SETTINGS_ROUTE}/notifications`
export const SCHEDULE_SETTINGS_ROUTE = `${SHELL_SETTINGS_ROUTE}/schedule`
export const LANGUAGE_SETTINGS_ROUTE = `${SHELL_SETTINGS_ROUTE}/language`
export const SHELL_SETTINGS_APP_LAUNCH_ROUTE = `${SHELL_SETTINGS_ROUTE}/applaunch`
export const CALENDAR_CONNECTED_ROUTE = '/calendar-connected'
export const TERMS_OF_SERVICE_ROUTE = '/terms-of-service'
export const LANDING_ROUTE = '/landing'
export const ROOT_ROUTE = '/'
export const MESSAGE_ROUTE = '/newmessage'
export const ACTIVITY_ROUTE = '/activity'
export const VOICEMAIL_ROUTE = '/activity?activityType=VOICEMAIL'
export const SEARCH_ROUTE = '/search'
export const START_MS_TEAMS_PLUGIN_ROUTE = '/start-goto-for-teams'
export const INTEGRATION_REDIRECT_PROXY_ROUTE = '/integration-redirect'
export const START_INTEGRATION_AUTHENTICATED_ROUTE = '/integration-auth-start'
export const END_INTEGRATION_AUTHENTICATED_ROUTE = '/integration-auth-end'

// These routes won't load extensions
export const SHELL_OPTIMIZED_PAGES_ROUTES = [
  LANDING_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  CALENDAR_CONNECTED_ROUTE,
  UNAUTHENTICATED_ERROR,
  GENERIC_ERROR,
  START_MS_TEAMS_PLUGIN_ROUTE,
  INTEGRATION_REDIRECT_PROXY_ROUTE,
]

const navigationIdsByRoute: { readonly [key: string]: string } = {
  ['/main']: '@home',
  ['/call']: '@call',
  [ACTIVITY_ROUTE]: '@activity',
  ['/voice/callhistory']: '@activity',
  [MESSAGE_ROUTE]: '@message',
  ['/messaging']: '@messaging',
  [VOICEMAIL_ROUTE]: '@voicemail',
  ['/voice']: '@voice',
  ['/voice/voicemails']: '@voicemail',
  ['/contactssearch']: '@contacts',
  ['/contacts']: '@contacts',
  ['/analytics']: '@analytics',
  ['/meetings']: 'oos-meeting-app',
  ['/trainings']: 'oos-training-app',
  ['/webinars']: 'oos-webinar-app',
  ['/engagements']: 'engagements',
  ['/contact-center']: 'contact-center',
  ['/contact-center-agent']: 'contact-center-agent',
  ['/contact-center-configuration']: 'contact-center-configuration',
  ['/contact-center-supervisor']: 'contact-center-supervisor',
  ['/inbox']: 'inbox',
}

export const getNavigationIdByRoute = (route: string): string => navigationIdsByRoute[route] ?? route
