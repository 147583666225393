import { decodeAndParse, stringifyAndEncode } from '../auth/utils'
import {
  getLocationOrigin,
  getLocationPathname,
  getURLSearchParams,
  navigateToExternal,
} from '../../common/dom-helpers'
import { END_INTEGRATION_AUTHENTICATED_ROUTE, START_INTEGRATION_AUTHENTICATED_ROUTE } from '../../common/routes'
import { app, authentication } from '@microsoft/teams-js'
import { getFeatureFlagValue } from '../feature-flags'
import { FeatureFlagsVariations } from '../feature-flags/models'
import { getShellLogger } from '../../common/logger'

const responseType = 'code'

const getState = () => {
  const urlSearchParams = getURLSearchParams()
  return decodeAndParse(urlSearchParams.get('state') ?? '')
}

/**
 * When the integration open an external window for the authentication, we load goto-app with a specific path
 * The first path start the authentication flow: redirectToAuthentication
 * The second path return the authentication code to goto-app running in teams: redirectToMsTeam.
 */
const redirectToAuthentication = () => {
  const urlSearchParams = getURLSearchParams()
  const config: { [name: string]: string } = {
    response_type: responseType,
    client_id: decodeURIComponent(urlSearchParams.get('authClientId') ?? ''),
    redirect_uri: getLocationOrigin() + END_INTEGRATION_AUTHENTICATED_ROUTE,
    code_challenge: decodeURIComponent(urlSearchParams.get('codeChallenge') ?? ''),
    state: stringifyAndEncode({
      authId: urlSearchParams.get('authId') ?? '',
      oauthRedirectMethod: urlSearchParams.get('oauthRedirectMethod') ?? '',
      hostRedirectUrl: urlSearchParams.get('hostRedirectUrl') ?? '',
    }),
    code_challenge_method: 'S256',
    url: decodeURIComponent(urlSearchParams.get('authUrl') ?? ''),
  }
  const url = new URL(config.url + '/oauth/authorize')
  for (const key in config) {
    url.searchParams.append(key, config[key])
  }
  navigateToExternal(url.toString())
}

const redirectToMsTeam = () => {
  const urlSearchParams = getURLSearchParams()
  const code = urlSearchParams.get(responseType) ?? ''
  try {
    const state = getState()
    if (state.oauthRedirectMethod === 'deeplink') {
      const clientRedirectUrl: string = state?.hostRedirectUrl?.replace('{result}', code)
      return navigateToExternal(clientRedirectUrl)
    }
    app.initialize().then(() => {
      authentication.notifySuccess(code)
    }).catch((e) => {
      getShellLogger().error('Authentication.notifySuccess failed', e)
    })
  } catch (e) {
    getShellLogger().error('Authentication state failed', e)
    redirectToAuthentication()
  }
}

const getAuthenticationRedirectAction: { [route: string]: () => void } = {
  [START_INTEGRATION_AUTHENTICATED_ROUTE]: redirectToAuthentication,
  [END_INTEGRATION_AUTHENTICATED_ROUTE]: redirectToMsTeam,
}

/**
 * Function to handle the authentication flow for the ms team integration
 * This handler will be used in the external window when we launch the authentication flow for ms-teams integration.
 * microsoft/teams-js javascript library has a limitation on which domain you can redirect when you start the authentication flow.
 * Because we have to use the same domain, this function is used to handle the redirect to the authentication flow when we open in an external window (outside ms teams)
 **/
export const handleIntegrationRedirectToAuthentication = () => {
  if (getFeatureFlagValue(FeatureFlagsVariations.SHELL_MS_TEAM_NO_PLUGIN, false)) {
    const locationPathname = getLocationPathname()
    getAuthenticationRedirectAction[locationPathname]?.()
  }
}
